var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "2 0 14 14" } },
    [
      _c("title", [_vm._v("Reporting")]),
      _c("g", { attrs: { id: "Capa_2", "data-name": "Capa 2" } }, [
        _c("g", { attrs: { id: "Capa_1-2", "data-name": "Capa 1" } }, [
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M15.22,4.69v8.62H2.78V4.69H15.22m.13-.78H2.65A.65.65,\n        0,0,0,2,4.56v8.88a.65.65,0,0,0,.65.65h12.7a.65.65,0,0,0,.65-.65V4.56a.65.65,0,0,0-.65-.65Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M8.1,6.9H4.46a.38.38,0,0,1-.39-.39.39.39,0,0,1,.39-.39H8.1a.4.4,0,0,1,.39.39A.39.39,0,0,1,8.1,6.9Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M8.1,9.24H4.46a.39.39,0,0,1-.39-.39.38.38,0,0,1,.39-.39H8.1a.39.39,0,0,1,.39.39A.4.4,0,0,1,8.1,9.24Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M8.1,11.57H4.46a.38.38,0,0,1-.39-.39.39.39,0,0,1,.39-.39H8.1a.4.4,0,0,1,.39.39A.39.39,0,0,1,8.1,11.57Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M10.39,8.82h-.7a.1.1,0,0,0-.11.11v2.71a.1.1,0,0,0,.11.11h.7a.1.1,0,0,0,.11-.11V8.93a.1.1,0,0,0-.11-.11Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M12.21,7.43h-.69a.11.11,0,0,0-.08,0,.09.09,0,0,0,0,.08v4.1a.09.09,0,0\n        ,0,0,.08.11.11,0,0,0,.08,0h.69a.14.14,0,0,0,.09,0,.11.11,0,0,0,0-.08V7.54a.11.11,\n        0,0,0,0-.08A.14.14,0,0,0,12.21,7.43Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M14.07,6.18a.09.09,0,0,0-.08,0h-.7a.09.09,0,0,0-.08,0,.1.1,0,0,0,0,.08v5.38a.1.1,\n        0,0,0,.11.11H14a.1.1,0,0,0,.11-.11V6.26A.1.1,0,0,0,14.07,6.18Z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }